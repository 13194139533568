.stripe label {
  color: rgb(51, 51, 51);
  font-weight: 300;
  letter-spacing: 0.025em;
  display: block;
}

.stripe input {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 8px;
  font-size: 18px;
  margin: 10px 0 20px 0;
  height: 40px;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  box-sizing: border-box;
}

.stripe input:focus {
  padding: 9px 14px;
  border: 2px solid rgb(126, 87, 194);
  outline: none;
}

.stripe input::placeholder {
  color: #aab7c4;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: block;
  height: 40px;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 8px;
  background: white;
  box-sizing: border-box;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
  box-shadow:
    rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;

  pointer-events: none !important;

  background-color: rgb(255, 255, 255);
  padding: 9px 14px;
  border: 2px solid rgb(126, 87, 194);
  outline: none;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}
